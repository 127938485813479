import { Grid } from '@mui/material';
import React, { Component } from 'react';


class InfoStatus extends Component {

    render() {
        return (
                <div className="status info">
                    <span>{ this.props.msg }</span>
                </div>
        );
    }
}

export default InfoStatus;