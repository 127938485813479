import React, { Component } from 'react';
import BlockIcon from '@mui/icons-material/Block';

class CameraDisabled extends Component {
    render() {
        return (
            <div className="camera-disabled-div">
                <BlockIcon /><br/>
                { (this.props.lang === "de" ? <span>Kamera ist deaktiviert!</span> : <span>The camera is disabled!</span>) }
            </div>
        );
    }
}

export default CameraDisabled;