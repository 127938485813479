import React, { Component } from 'react';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import KeyboardOutlinedIcon from '@mui/icons-material/KeyboardOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import LogoutIcon from '@mui/icons-material/Logout';
import LockIcon from '@mui/icons-material/Lock';
import NoEncryptionIcon from '@mui/icons-material/NoEncryption';
import { Grid, OutlinedInput, Typography } from '@mui/material';
import SuccessStatus from './SuccessStatus';
import ErrorStatus from './ErrorStatus';
import InfoStatus from './InfoStatus';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { HTTPHelper, idbHelper, scanItemHelper, validationHelper, syncHelper, devHelper, authHelper } from "../Helpers"
import CameraDisabled from './CameraDisabled';
import EventLoadModal from './EventLoadModal';
import EventControlModal from './EventControlModal';
import EventLoadHints from './EventLoadHints';
import {Howl, Howler} from  'howler';
import Success from './success.wav';
import Error from './error.wav';
import OpenEvent from './open_event.wav';
import { ControlConfig } from "../Libraries/ControlConfig";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
let textInputTimer = null;
let hidden;
let visibilityChange;

class Scanner extends Component {
    userContext = this.props.userContext;
    setUserContext = this.props.setUserContext;
    token = this.props.token;
    setToken = this.props.setToken;
    notification = this.props.notification;

    //Values
    state = {
        // userContext: this.userContext,
        values: {
            codeTxt: "",
            scannedStr: ""
        },
        config: {
            isCameraActive: false,
            scanDelay: 2000,
            isLightOn: false,
            isEventLoaded: false,
            language: 'de',
            powerSaveHandler: null,
            powerSaveTimeout: 120,   //Seconds of inactivity to wait before turning off camera and torch
            scanned: 0,
            uploaded: 0,
            scannedInQueue: 0,
            showSpinner: false,
            supportsTorch: true
        },
        syncHandlers: {
            syncUpload: null,
            syncDownload: null,
            syncEventDetails: null
        },
        event: { id: 0, code: "", name: "" },
        eventLoadedStats: {
          showDialog: false,
          showHints: false,
          scannedStr: "",
          eventCode: "",
          eventId: "",
          name: "",
          venue_name: "",
          venue_city: "",
          starts_at_date: "",
          admission_total_ticket_count: null,
          admission_outside_count: null,
          admission_inside_count: null,
          scanned_items_loaded: null,
          last_update: "",
          messagesDict: {
            "event.scannedStr": {"de": "Gescannter Code", "en": "Scanned code"},
            "event.eventCode": {"de": "Eventcode", "en": "Event code"},
            "event.eventId": {"de": "Eventid", "en": "Event Id"},
            "event.name": {"de": "Name des Events", "en": "Event name"},
            "event.venue_name": {"de": "Veranstaltungsort", "en": "Venue name"},
            "event.venue_city": {"de": "Stadt", "en": "Venue city"},
            "event.starts_at_date": {"de": "Event beginnt am", "en": "Event starts on"},
            "event.admission_total_ticket_count": {"de": "Gesamtzahl der Tickets", "en": "Total tickets"},
            "event.admission_outside_count": {"de": "Tickets außerhalb des Veranstaltungsortes", "en": "Tickets outside of the venue"},
            "event.admission_inside_count": {"de": "Tickets innerhalb des Veranstaltungsortes", "en": "Tickets inside of the venue"},
            "event.scanned_items_loaded": {"de": "Gesamtzahl der Gescannte Items", "en": "Total scanned items"},
          }
        },
        eventControlConfig: {
            showDialog: false,
            scannedStr: "",
            showStatistics: false,
            emergencyMode: false,
            messagesDict: {
                "event.scannedStr": {"de": "Gescannter Code", "en": "Scanned code"},
                "event.showStatistics": {"de": "Einlass-Statistik in der App anzeigen", "en": "Show admission statistics in the app"},
                "event.activateEmergencyMode": {"de": "Notfallmodus ein-/ausschalten", "en": "Turn emergency mode ON / OFF"},
                "event.chooseLanguage": {"de": "Sprache auswählen", "en": "Choose language"},
                "event.showEventDetails": {"de": "Statistische Daten", "en": "Statistical details"},
                "event.syncNow": {"de": "Gescannte Tickets jetzt synchronisieren", "en": "Synchronize scanned tickets now"},
                "event.on": {"de": "Ein", "en": "On"},
                "event.off": {"de": "Aus", "en": "Off"},
                "event.show": {"de": "Anzeigen", "en": "Show"},
                "event.closeEvent": {"de": "Event schließen", "en": "Close event"},
                "event.logout": {"de": "Abmelden", "en": "Log out"}
            }
        },
        ticket: { 
            code: "", 
            firstName: "",
            lastName: "",
            validFrom: "",
            validThrough: "",
            category: "",
            seating: "",
            admissionCount: null,
            status: "",
            errorCode: []  //[0=valid]
        },
        errorCodes: [
            ["Gültig", "Valid"],        //0 = valid
            ["Ungültig", "Invalid"],     //1 = Ticket status invalid
            ["Ticket bereits entwertet!", "Ticket already validated"],     //2 = Ticket has 0 admissions left
            ["Ungültig Ticket Datum/Uhrzeit", "Invalid ticket date/time"],     //3 = Ticket was scanned outside of allowed date/time
            ["Ticket wurde nicht gefunden", "Ticket not found"], //4 = Ticket was not found in the Scan-Queue-Items Store in indexedDB
            ["Erfüllt nicht die Anforderungen des Notfallmodus", "Does not meet requirements of Emergency Mode"] //When the App is in Emergency Mode, only a number of minimum 10 digits will pass as a ticket code
        ],
        configCodes: [
            //Configuration codes for "op" (Control), select = "01"(View Event Details), "02"(Edit Settings), "03" (Delete)
            {op: "315", select: ["01","02","03"]}
        ],
        scannerTemplate: {
            "id":  "", //"<long> - Event-ID",
            "data": {
                "event_type": "",  //"<[ticket_change|scan_failed|scan_failed_invalid_time]>",
                "code": "",  //"<string> - Ticket-Code",
                "state": "",  //"<invalidated|revalidated > - Status des Tickets",
                "admission_count": "",  //"<int> - wie oft ein Ticket noch gescannt werden kann",
                "lat": "",  //"<decimal|null> - geo latitude",
                "lng": "",  //"<decimal|null> - geo longitude",
                "acc": "",  //"<string> - geo accuracy",
                "uid": "",  //"<string> - eineindeutige ID des Scanners",
                "changed_at": "",  //"<YYYY-MM-ddTHH:mm:ss> - Zeit des Scans"
            }
        }
    }
    
    componentDidMount(){
        // console.log("THE SCANNER COMPONENT HAS BEEN MOUNTED");
        //Initializing App through state,
        //If there is an event saved on the 'events' store in indexDB,
        //then restore it and prepare app for scanning tickets
        let dbPromise = idbHelper.openDb();
        let that = this;
        let config = this.state.config;
        let stateEvent = this.state.event;
        let eventControlConfig = this.state.eventControlConfig;

        this.toogleSpinner(true);

        config.supportsTorch = this.checkTorchSupport();
        this.bindVisibilityHandler();
        
        idbHelper.getSettings(dbPromise).then((settings) => {
            if(settings && (settings.language === "de" || settings.language === "en")){
                config.language = settings.language;
                stateEvent.code = settings.eventCode;
                eventControlConfig.showStatistics = settings.control_showStatistics;
                eventControlConfig.emergencyMode = settings.control_emergencyMode;
                if(!isNaN(settings.uploaded)){
                    config.uploaded = settings.uploaded;
                }
                if(!isNaN(settings.scanned)){
                    config.scanned = settings.scanned;
                }
                if(!isNaN(settings.scanned)){
                    config.scannedInQueue = settings.scannedInQueue;
                }
            }
            else{
                config.language = "de";
                idbHelper.saveSettings(dbPromise, {id: 1, eventCode: "", eventId: "", language: config.language, scanned: 0, uploaded: 0, scannedInQueue: 0, control_showStatistics: false, control_emergencyMode: false});
            }
            that.setState({config: config});
        });
        idbHelper.getEvents(dbPromise).then((dbEvent) => {
            if(dbEvent.length > 0){
                console.log("Loading event by means of state initialization");
                this.notification("success","event.loading", this.state.config.language);
                let currentEvent = dbEvent[0];
                let config = that.state.config;

                stateEvent.id = currentEvent.id;
                stateEvent.name = currentEvent.tour.name;
                stateEvent.venue_city = currentEvent.venue.city.name;
                stateEvent.starts_at_date = currentEvent.starts_at_date;
                config.isEventLoaded = true;
                
                that.setState({event: stateEvent, config: config});

                //Register Synch recurrent tasks
                syncHelper.registerSyncTasks(that);
            }
        }).finally(() => {
            this.toogleSpinner(false);
            setTimeout(this.toogleCamera, 1000);
        });
        this.refreshBatterySaver();
    }

    bindVisibilityHandler = () => {
        if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
            hidden = "hidden";
            visibilityChange = "visibilitychange";
        } else if (typeof document.msHidden !== "undefined") {
            hidden = "msHidden";
            visibilityChange = "msvisibilitychange";
        } else if (typeof document.webkitHidden !== "undefined") {
            hidden = "webkitHidden";
            visibilityChange = "webkitvisibilitychange";
        }
        document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
    }

    handleVisibilityChange = () => {
        let config = this.state.config;

        if (document[hidden]) {
            config.isCameraActive = false;
        } else {
            config.isCameraActive = true;
        }
        this.setState({config: config});
    }

    checkTorchSupport = () => {
        let supportedConstraints = navigator?.mediaDevices?.getSupportedConstraints();

        if(supportedConstraints === null || supportedConstraints === undefined ){
            return false;
        }
        else if(typeof supportedConstraints === "object"){
            if( supportedConstraints.hasOwnProperty("torch") ){
                return true;
            }
            else{
                return false;
            }
        }
        else{
            return false;
        }
    }

    refreshBatterySaver() {
        let config = this.state.config;

        if(config.powerSaveHandler){
            clearTimeout(config.powerSaveHandler);
        }

        config.powerSaveHandler = setTimeout(() => {
            let conf = this.state.config;
            conf.isCameraActive = false;
            config.isLightOn = false;
            this.setState({config: conf});
        }, config.powerSaveTimeout*1000);
        this.setState({config: config});
    }

    populateEventLoadDetails = (scannedStr, eventCode, eventId, data) => {
        let details = this.state.eventLoadedStats;
        details.scannedStr = scannedStr;
        details.eventCode = eventCode;
        details.eventId = eventId;
        details.name = data.tour.name;
        details.venue_name = data.venue.name;
        details.venue_city = data.venue.city.name;
        details.starts_at_date = data.starts_at_date;
        details.admission_total_ticket_count = data.admission_total_ticket_count;
        details.admission_outside_count = data.admission_outside_count;
        details.admission_inside_count = data.admission_inside_count;
        details.scanned_items_loaded = data.total_item_count;
        details.last_update = new Date(data.last_update).toLocaleString();
        this.setState({eventLoadedStats: details});
    }

    soundPlay = (src) => {
        const soundClips = [
            { title: 'success', sound: Success},
            { title: 'error', sound: Error},
            { title: 'open_event', sound: OpenEvent}
        ];
        const soundSrc = soundClips.find((clip) => { return clip.title === src}).sound;        
        const sound = new Howl({src: [soundSrc] });
        Howler.volume(1.0);
        sound.play();
    }

    toogleSpinner = (show=false) => {
        let config = this.state.config;
        config.showSpinner = show;
        this.setState({config: config});
    }

    toogleCamera = () => {
        let config = this.state.config;
        config.isCameraActive = !config.isCameraActive;
        if(!config.isCameraActive){ config.isLightOn = false; }
        this.setState({config: config});
        this.refreshBatterySaver();
    }

    logout = () => {
        let isEventLoaded = this.state.config.isEventLoaded;
        if(!isEventLoaded){
            //If no event is loaded, it is safe to sign the current user out
            authHelper.logoutUser(this.userContext, this.setUserContext, this.setToken);
        }
        else{
            //Before sign out, all events must be closed and no scanned items are pending from synchronization
            this.notification("error", "event.logout-failed-event", this.state.config.language);
        }
    }

    render() {
        //Functions
        let dbPromise = idbHelper.openDb();

        const reduceScanDelay = () => {
            let config = this.state.config;
            config.scanDelay = 600;
            this.setState({config: config});
            this.toogleSpinner(true);
            setTimeout( (() => {this.toogleSpinner(false)}), 500);
        }

        const onReaderError = (error) => {
            console.log("Scan error: ",error);
        }
    
        const onReaderScan = (err, result) => {
            let values = this.state.values;
            let config = this.state.config;
            
            //result has these properties: timestamp, text, resultPoints, resultMetadata, rawBytes, numBits, format
            if (result){
                if(config.scanDelay > 1000){
                    reduceScanDelay();
                }
                let resultTXT = result.text.trim();
                this.refreshBatterySaver();
                //Check if the scanned string is the same as the current value saved on 'values.scannedStr'
                //Should that be the case, return, for avoiding multiple simultaneous registration of codes
                if(resultTXT === values.codeTxt.trim()){
                    console.log("onReaderScan: ignoring scanned string since it is equal to the last scan");
                    values.codeTxt = "";
                    this.setState({values: values});    
                    return;
                }
                values.codeTxt = resultTXT;
    
                if(!this.state.config.isEventLoaded){
                    verifyEventCode(values.codeTxt);
                }
                else{
                    verifyTicketCode(values.codeTxt);
                }
            }
            else  {
                this.setState({data: ""});
            }
        }
        
        //Handles a manual text input (event or ticket code)
        const handleManualCodeInput = (e) => {
            let values = this.state.values;
            let inputTxt = e.target.value.trim();
            const timeToWait = inputTxt.length < 15 ? 3000 : 1200;
            
            this.refreshBatterySaver();

            if(inputTxt === values.codeTxt){
                //If the new code typed is the same as the previously saved then ignore it and return
                console.log("Ignoring typed input, is the same as last one");
                return;
            }
            values.codeTxt = inputTxt;
            this.setState({values: values});

            clearTimeout(textInputTimer);
            textInputTimer = setTimeout(() => {
                if(this.state.config.isEventLoaded){        
                    verifyTicketCode(inputTxt);
                }
                else{
                    verifyEventCode(inputTxt);
                }
            }, timeToWait);
        }

        const verifyEventCode = (code) => {
            this.toogleSpinner(true);
            let values = this.state.values;
            let codeTXT = code.trim();

            values.codeTxt = codeTXT;
            let that = this;

            let segments = values.codeTxt.split("-");

            if(segments.length === 3 && segments[1].length > 0 && segments[2].length > 0 ){
                //Turn off camera while loading Event to prevent double scanning the same event code
                this.state.config.isCameraActive = false;
                this.state.config.isLightOn = false;

                idbHelper.getEvents(dbPromise).then((dbEvent) => {
                    if(dbEvent.length > 0){
                        console.log("Not loading this event. There is already another event loaded in indexedDB");
                        that.notification("error", "event.not-opening", that.state.config.language);
                        return;
                    }
                    let eventLoaded = loadEvent(values.codeTxt, segments[1].trim(), segments[2].trim());
                    eventLoaded.then((result) => {
                        if(result){
                            values.codeTxt = "";
                            values.scannedStr = "";
                        }
                        that.setState({values: values});
                    }).finally(() => {this.toogleSpinner(false)});
                });
            }
            else{
                this.toogleSpinner(false);
                if(codeTXT.length > 40){
                    this.notification("error", "event.bad-format", this.state.config.language)
                }
            }
        };

        const verifyTicketCode = (code) => {
            let values = this.state.values;
            let ticket = this.state.ticket;
            let eventControlConfig = this.state.eventControlConfig;
            let codeTXT = code.trim();
            let that = this;

            if(codeTXT === "##99#01"){
                //DEV Code use to print all Ticket code in the event
                let ticketCodes = [];
                let ticketStatus = [];
                devHelper.getAllTicketCodes(dbPromise).then( (items) => {
                    items.forEach( item => {
                            ticketCodes.push(item.data.code);
                            ticketStatus.push(item.id + " : " + item.data.code + " : " + item.data.state);
                    });
                    // console.log("DEV: All Ticket Codes: ",ticketCodes);
                    // console.log("DEV: All Non-Void Ticket Status: ",ticketStatus);
                });
                
                return;
            }
            if(codeTXT === "##99#02"){
                //DEV Code use to print all Ticket codes that are not VOID in the event
                let ticketCodes = [];
                let ticketStatus = [];
                devHelper.getAllTicketCodes(dbPromise).then( (items) => {
                    items.forEach( item => {
                        if( ticketCodes.indexOf(item.data.code) < 0 && item.data.state != "void" ){
                            ticketCodes.push(item.data.code);
                            ticketStatus.push(item.data.code + " : " + item.data.state);
                        }
                    });
                    // console.log("DEV: All Non-Void Ticket Codes: ",ticketCodes);
                    // console.log("DEV: All Non-Void Ticket Status: ",ticketStatus);
                });
                
                return;
            }

            if(codeTXT === "16081977"){
                //By customer request 16081977 is an alternative code to access the Control Panel
                //Rewrite the alternative code as the official control code
                codeTXT = "##" + this.state.configCodes[0].op + "#" + this.state.configCodes[0].select[1];
            }

            if(codeTXT.startsWith("##")){
                //If the provided string starts with ## it is evaluated as a configuration code
                //Format defined for configuration codes is: ##OP#Selection
                //Where OP is the Operation code and Selection is the Option selector, both are numeric values
                ControlConfig(codeTXT, this);
                return;
            }

            if(eventControlConfig.emergencyMode === true){
                if(codeTXT === ticket.code){
                    //If the new code typed is the same as the previously saved in the current ticket then ignore it and return
                    console.log("Ignoring request: ticket code is the same as the last ticket code processed");
                    return;
                }

                //If Emergency Mode (Notfallmodus) is activated, then every numeric code scanned will be considered a valid ticket
                //Verify if the scanned string is a number and has minimum 10 digits
                ticket.code = codeTXT;
                values.codeTxt = "";
                values.scannedStr = "";

                if(codeTXT.length >= 10 && !isNaN(codeTXT)){
                    //Proceed to present success scanned notification
                    // console.log("EMERGENCY MODE ON: verifyTicketCode, 'Scanned string pass as a ticket':",codeTXT);
                    ticket.status = "valid";
                }
                else{
                    //Proceed to present generic error notification
                    // console.log("EMERGENCY MODE ON: verifyTicketCode, 'Scanned string does not meet minimum requirements':",codeTXT);
                    ticket.status = "invalid";
                }
                ticket.status === "valid" ? this.soundPlay("success") : this.soundPlay("error");
                this.setState({ticket: ticket, values: values});
                return;
            }

            let segments = codeTXT.split("-");
            if(segments.length === 3){
                //If the provided string meets the Event code format, then inform the user and advise to close currently loaded event
                console.log("Ignoring request: ticket code seems to be an event code");
                this.notification("error", "ticket.event-format", this.state.config.language);
                return;
            }
            if(isNaN(codeTXT)){
                //If the provided string does not represent a number, then ignore the provided code and return
                console.log("Ignoring request: ticket code is not in a proper number format");
                this.notification("error", "ticket.bad-format", this.state.config.language);
                return;
            }
            if(codeTXT === ticket.code){
                //If the new code typed is the same as the previously saved in the current ticket then ignore it and return
                console.log("Ignoring request: ticket code is the same as the last ticket code processed");
                return;
            }
            this.toogleSpinner(true);
            values.codeTxt = codeTXT;
            registerTicket(values.codeTxt).then((isTicketRegistered) => {
                if(isTicketRegistered){
                    values.codeTxt = "";
                    values.scannedStr = "";
                    syncHelper.syncTicketCount(dbPromise, that, [null, (that.state.config.scanned +1)]);
                }
                else{
                    // console.log("Ticket was not registered: ",values.codeTxt);
                }
                that.state.ticket.status === "valid" ? that.soundPlay("success") : that.soundPlay("error");
                that.setState({values: values});
            }).finally(() => {this.toogleSpinner(false)});
        };

        const loadEvent = (scannedStr, eventCode, eventId) => {
            let event = this.state.event;
            let config = this.state.config;
            let scanQueueItems = [];
            let that = this;
            let currentLanguage = that.state.config.language;
            let eventLoadedStats = that.state.eventLoadedStats;
            let eventControlConfig = this.state.eventControlConfig;

            let httpHelper = HTTPHelper(eventCode, eventId, this.userContext, this.setUserContext, this.setToken);
            if(httpHelper === null){
                return false;
            }
            
            //Load the event details
                return httpHelper.getEvent().then((res) => {
                    if(res === undefined || res === null){
                        that.notification("error", "event.failed-fetch-event", that.state.config.language);
                        console.log("Event not loaded, skipping response handling");
                        return false; 
                    }
                    if(res.status === 200){
                        //If the event details could be retrieved from the server
                        return res.json().then(function(data) {      
                            data.last_update = new Date().toISOString();
                            event.code = eventCode;
                            event.id = data.id;
                            event.name = data.tour.name;
                            event.venue_city = data.venue.city.name;
                            event.starts_at_date = data.starts_at_date;
                            config.isEventLoaded = true;
                            config.uploaded = 0;
                            config.scanned = 0;
                            config.scannedInQueue = 0;
                            eventControlConfig.showStatistics = false;
                            eventControlConfig.emergencyMode = false;

                            //Save Event to indexedDB
                            idbHelper.saveEvent(dbPromise, data);
                            idbHelper.saveSettings(dbPromise, {
                                                                id: 1, 
                                                                eventCode: event.code, 
                                                                eventId: event.id, 
                                                                language: currentLanguage, 
                                                                scanned: config.scanned, 
                                                                uploaded: config.uploaded, 
                                                                scannedInQueue: config.scannedInQueue,
                                                                control_showStatistics: eventControlConfig.showStatistics,
                                                                control_emergencyMode: eventControlConfig.emergencyMode
                                                            });
                            that.setState({event: event, config: config});
                            
                            //Uncomment following line if it is required to show the Event Details Modal
                            that.populateEventLoadDetails(scannedStr, eventCode, eventId, data);
                            eventLoadedStats.showHints = true;

                        //Register Sync recurrent tasks
                        syncHelper.registerSyncTasks(that);
                        
                        //Load Scan-Queue-Items and save them in local DB
                        return httpHelper.getAllScanQueueItems().then((res) => {
                            if(res.status === 200){
                                return res.text().then(function(data) {
                                    scanQueueItems = scanItemHelper.parseScanitems(data);
                                    idbHelper.saveScanQueueItems(dbPromise, scanQueueItems).then(() => { 
                                        that.notification("success", "event.loaded", that.state.config.language);
                                     });
                                    
                                    return scanQueueItems;
                                });
                            }
                            else{
                                that.notification("error", "event.failed-fetch-sqi", that.state.config.language);
                                console.log("Error during getAllScanQueueItems");
                                return false;
                            }
                        }).catch((err) => {
                            that.notification("error", "event.failed-fetch-sqi", that.state.config.language);
                            console.log("Error on ScanQueueItems load: ",err);
                            return false;
                        });
                    });
                }
                else if(res.status === 401){
                    //Server response when the JWT Access token sent is expired or the user does not have the rights to load this event
                    that.notification("error", "event.unauthorized", that.state.config.language);
                    console.log("Server responded with a 'unauthorized' error: ",res.status);
                    return false;
                }
                else if(res.status === 403){
                    //Server response when trying to load an event more than 7 days before the event start
                    that.notification("error", "event.invalid-date", that.state.config.language);
                    console.log("Server responded with a 'too early to load event' error: ",res.status);
                    return false;
                }
                else if(res.status === 404){
                    //Server response when the event was not found
                    that.notification("error", "event.event-not-found", that.state.config.language);
                    console.log("Server responded with a 'event not found' error: ",res.status);
                    return false;
                }
                else{
                    that.notification("error", "event.failed-get-event", that.state.config.language);
                    console.log("Server responded with an error: ",res.status);
                    return false;
                }
            }).catch((err) => {
                that.notification("error", "event.failed-parse-event-details", that.state.config.language);
                console.log("Error on Event load: ",err);
                return false;
            });
        };

        const registerTicket = (ticketCode) => {
            let ticket = this.state.ticket;
            let event = this.state.event;
            let scannerItem = JSON.parse(JSON.stringify(this.state.scannerTemplate));
            let that = this;
            
            //Validation process steps:
            // 1) Get Scan-Queue-Items list for this ticket code from indexedDB
            //
            return idbHelper.getTicketScanQueueItems(dbPromise, ticketCode).then(function(ticketScanQueueItems){
                
                //First of all the ticket MUST have at least ONE Scan-Queue-Item
                //Which gets pushed to Server DB when the ticket is sold/created
                //
                let wasTicketFound = (ticketScanQueueItems.length > 0) ? true : false;

                //(Queue-Item.state == "sold" OR Queue-Item.state == "revalidated"): then Ticket is VALID
                //
                let isStatusValid = validationHelper.checkItemState(ticketScanQueueItems);
                
                //Additionally it MUST have an "admission_count" > 0,
                //so that this registration process can subtract 1 from this property
                //
                let hasAccessAvailable = validationHelper.checkItemAccess(ticketScanQueueItems);

                //Additionally it MUST honour the 'valid_from'/'valid_through' properties ONLY if anyone of them exist
                //
                let isTimeValid = validationHelper.checkTime(ticketScanQueueItems);

                let hasScannerItemsAvailable;
                
                return validationHelper.checkScannerItemsAccess(dbPromise, ticketCode).then((result) => {
                    hasScannerItemsAvailable = result;

                    if(wasTicketFound){
                        //Then get the ticket details from most recent Scan-Queue-Items
                        let ticketDetails = validationHelper.getTicketDetails(ticketScanQueueItems)
                        ticket.code =       ticketDetails.code;
                        ticket.firstName =  ticketDetails.firstName;
                        ticket.lastName =   ticketDetails.lastName;
                        ticket.validFrom =  ticketDetails.validFrom;
                        ticket.validThrough      =  ticketDetails.validThrough;
                        ticket.category   = ticketDetails.category;
                        ticket.seating     = ticketDetails.seating;
                        
                        if(isStatusValid && hasAccessAvailable && hasScannerItemsAvailable && isTimeValid){
                        //This means the ticket is completely valid
                            // console.log("Ticket validation, isStatusValid(sold or revalidated): ",isStatusValid);
                            // console.log("Ticket validation, hasAccessAvailable (admission count > 0): ",hasAccessAvailable);
                            // console.log("Ticket validation, isTimeValid (valid_through <= currentDateTime => valid_from): ", isTimeValid);
                            // console.log("Ticket validation, hasScannerItemsAvailable (admission count > 0): ", hasScannerItemsAvailable);

                            ticket.admissionCount = ticketDetails.admissionCount -1;
                            ticket.status = "valid";
                            ticket.errorCode = [0]; //0 = valid

                            scannerItem.data.event_type = "ticket_change";
                            scannerItem.data.state = ticket.admissionCount > 0 ? "revalidated" : "invalidated";
                        }
                        else{
                        //This means the ticket was found in local DB (indexedDB) BUT it is NOT valid
                            // console.log("Ticket validation, ticket is invalid. isStatusValid: ", isStatusValid);
                            // console.log("Ticket validation, ticket is invalid. hasAccessAvailable:", hasAccessAvailable);
                            // console.log("Ticket validation, ticket is invalid. isTimeValid:", isTimeValid);
                            // console.log("Ticket validation, ticket is invalid. hasScannerItemsAvailable: ", hasScannerItemsAvailable);

                            ticket.status = "invalid";
                            ticket.admissionCount =  (!hasScannerItemsAvailable) ? 0 : ticketDetails.admissionCount;
                            ticket.errorCode = [];
                            scannerItem.data.state = "invalidated";
                            
                            if(!isStatusValid){      scannerItem.data.event_type = "scan_failed"; ticket.errorCode.push(1);}      //1 = Ticket status invalid
                            if(!hasAccessAvailable || !hasScannerItemsAvailable){ scannerItem.data.event_type = "scan_failed"; ticket.errorCode.push(2); }     //2 = Ticket has 0 admissions left
                            if(!isTimeValid){        scannerItem.data.event_type = "scan_failed_invalid_time"; ticket.errorCode.push(3);} //3 = Ticket was scanned outside of allowed date/time
                            
                        }

                        //Create New Scanner Item Record and save to localDB (indexedDB)
                        scannerItem.id = event.id;
                        scannerItem.data.code = ticket.code;
                        scannerItem.data.admission_count = ticket.admissionCount;
                        scannerItem.data.lat = "";
                        scannerItem.data.lng = "";
                        scannerItem.data.acc = "";
                        scannerItem.data.uid = "";
                        scannerItem.data.changed_at = new Date().toISOString();
                        
                        return idbHelper.saveScannerItem(dbPromise, scannerItem).then((result) => {
                            that.setState({ticket: ticket});
                            return true;
                        });
                    }
                    else{
                    //This means the ticket was NOT found in local DB (indexedDB).
                        //Possible causes are: this ticket does not belong to this event, the ticket number does not exist,
                        //the local DB (indexedDB) is not in synch with the Server DB, among other
                        Object.keys(ticket).map((prop)=>{ticket[prop] = ""});
                        ticket.code = ticketCode;
                        ticket.admissionCount = null;
                        ticket.status = "invalid"
                        ticket.errorCode = [4]; //4 = Ticket was not found in the Scan-Queue-Items Store in indexedDB
                        that.setState({ticket: ticket});
                        return false;
                    }

                });
            }).catch();
        };

        const toogleTorch = () => {
            let config = this.state.config;
            config.isLightOn = !config.isLightOn;
            if(config.isLightOn){ config.isCameraActive = true; }
            this.setState({config: config}); 
            this.refreshBatterySaver();
        }

        const changeLaguage = () => {
            let config = this.state.config;

            if(config.language === "de"){
                config.language = "en";
            }
            else{
                config.language = "de";
            }

            this.setState({config: config});
            idbHelper.getSettings(dbPromise).then((settings) => {
                if(settings){
                    settings.language = config.language;
                    idbHelper.saveSettings(dbPromise, settings);
                }
                else{
                    idbHelper.saveSettings(dbPromise, {id: 1, eventCode: "", eventId: "", language: config.language, scanned: config.scanned, uploaded: config.uploaded, scannedInQueue: config.scannedInQueue});
                }
            });
        }

        const closeEvent = () => {
            //Close event after user clicked on "close event" icon
            //This will verify if there are any Scanner Ticket Items that
            //are pending from sending to the server, when this is the case,
            //the currently loaded event will not be closed until 0 items are left
            let that = this;

            this.toogleSpinner(true);
            idbHelper.getAllScannerItems(dbPromise).then((scannerItems) => {
                if(scannerItems.length > 0){
                    console.log("Not closing this event. There are still scanned tickets pending from synchronization with server");
                    this.notification("error", "event.cannot-close", this.state.config.language);
                    return;
                }
                else{
                    //Clear event store
                    idbHelper.clearStore(dbPromise, "event").then(() => {
                    //Clear scanqueueitems store
                        idbHelper.clearStore(dbPromise, "scanqueueitems").then(() => {
                            //Update settings store
                            idbHelper.clearStore(dbPromise, "settings").then(() => {
                            //Update event,config session variable
                                let event = that.state.event;
                                let config = that.state.config;
                                let ticket = that.state.ticket;
                                let values = that.state.values;
                                let eventControlConfig = that.state.eventControlConfig;

                                Object.keys(event).map((prop)=>{event[prop] = ""});
                                Object.keys(ticket).map((prop)=>{ticket[prop] = ""});
                                Object.keys(values).map((prop)=>{values[prop] = ""});
                                config.isEventLoaded = false;
                                config.scanned = 0;
                                config.uploaded = 0;
                                config.scannedInQueue = 0;
                                eventControlConfig.showStatistics = eventControlConfig.emergencyMode = false;
                                that.setState({event: event, config: config, ticket: ticket, values:values, eventControlConfig: eventControlConfig});
                                //Unregister Sync recurrent tasks
                                syncHelper.unregisterSyncTasks(that);
                                this.notification("success", "event.closed", this.state.config.language);
                            });
                        });
                    })
                    
                }
            }).finally(() => {this.toogleSpinner(false)});
        }

        const unbindTicket = () => {
            let ticket = this.state.ticket;
            let values = this.state.values;

            Object.keys(ticket).map((prop)=>{ticket[prop] = ""});
            Object.keys(values).map((prop)=>{values[prop] = ""});
            ticket.admissionCount = null;
            ticket.errorCode = [];
            this.setState({ticket: ticket, values: values});
        }

        const unmountEventDialog = () => {
            let eventLoadedStats = this.state.eventLoadedStats;
            let eventControlConfig = this.state.eventControlConfig;
            let config = this.state.config;
            let values = this.state.values;

            //Dismiss dialog element and clear out event details from the dialog element 
            eventLoadedStats.showDialog = false;
           
            if(eventControlConfig.showDialog === false){
                config.isCameraActive = true;
            }
            
            values.codeTxt = "";
            values.scannedStr = "";

            this.setState({eventLoadedStats: eventLoadedStats, config: config, values: values});
        }

        const unmountControlDialog = () => {
            let eventControlConfig = this.state.eventControlConfig;
            let config = this.state.config;
            let values = this.state.values;
            
            // //Dismiss dialog element and clear out event details from the dialog element 
            eventControlConfig.showDialog = false;
            eventControlConfig.scannedStr = "";
            config.isCameraActive = true;
            values.codeTxt = "";
            values.scannedStr = "";
            this.setState({eventControlConfig: eventControlConfig, config: config, values: values});
        }

        const unmountHintsDialog = () => {
            let eventLoadedStats = this.state.eventLoadedStats;
            let config = this.state.config;
            eventLoadedStats.showHints = false;
            config.isCameraActive = true;
            
            this.setState({eventLoadedStats: eventLoadedStats, config: config});
        }

        const updateControlSettings = (eventControlConfig) => {
            let config = this.state.config;
            let syncEventDetails = this.state.syncHandlers.syncEventDetails;
            let emergencyMode = this.state.eventControlConfig.emergencyMode;
            
            //Show or Hide Statistics on App UI
            if(eventControlConfig.showStatistics === true && (syncEventDetails === null || syncEventDetails < 1) ){
                console.log("Registering an event");
                syncHelper.registerSyncTasks(this);
            }
            else{
                if(syncEventDetails === null || syncEventDetails < 1)
                console.log("To unregister an event");
                syncHelper.unregisterEventDetailsSync(this);
            }

            if( emergencyMode === true ){
                activateEmergencyMode();
            }
            else{
                deactivateEmergencyMode();
            }

            idbHelper.getSettings(dbPromise).then((settings) => {
                if(settings){
                    settings.id = 1;
                    settings.control_showStatistics = eventControlConfig.showStatistics;
                    settings.control_emergencyMode = eventControlConfig.emergencyMode;
                    idbHelper.saveSettings(dbPromise, settings);
                }
                else{
                    idbHelper.saveSettings(dbPromise, {id: 1, eventCode: "", eventId: "", language: config.language, scanned: config.scanned, uploaded: config.uploaded, scannedInQueue: config.scannedInQueue, control_showStatistics: false, control_emergencyMode: false});
                }
            });
            this.setState({eventControlConfig: eventControlConfig});
        }

        const openEventDetailsModal = () => {
            let eventLoadedStats = this.state.eventLoadedStats;
            eventLoadedStats.showDialog = true;
            this.setState({eventLoadedStats: eventLoadedStats});
        }

        const activateEmergencyMode = () => {
            unbindTicket();
        }

        const deactivateEmergencyMode = () => {
            unbindTicket();
        }

        return (
            <div className="scanner-wrapper">
                <header id="top-header" className="App-header">
                    <Grid container>
                        <Grid item xs={2} sm={3} className="logo-data-container dummy1">
                            <div id="counter-section" className={ this.state.eventControlConfig.emergencyMode === true ? "emergency-mode" : ""}>
                                <a href='/'><img className='logo-img' src='/logo192.png' /> </a>
                                <div className="circleicon-div">
                                    <CircleIcon
                                        color={ this.state.eventControlConfig.emergencyMode === true ? "error" : "success"}
                                    />
                                </div>
                            </div>
                            <div id="network-info" className="network-info">
                                <div className="counter-values">
                                    <span className="counter-number">{this.state.config.scanned}</span>
                                    <ArrowUpwardIcon />
                                </div>
                                <div className="counter-values">
                                    <ArrowDownwardIcon />
                                    <span className="counter-number">{this.state.config.scannedInQueue}</span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={8} sm={6} className="dummy2">
                            <div id="event-title-info">
                                <p className="event-title">{ this.state.config.isEventLoaded ? this.state.event.name : (this.state.config.language === "de" ? "Kein Event geladen" : "Event not loaded") }</p>
                                <div className="event-city-date-wrapper">
                                    { this.state.config.isEventLoaded ? (<p className="event-city">{this.state.config.language === "de" ? "Stadt" : "City"}: {this.state.event.venue_city}</p>) : "" }
                                    { this.state.config.isEventLoaded ? (<p className="event-date">{this.state.config.language === "de" ? "Datum" : "Date"}: {this.state.event.starts_at_date}</p>) : "" }
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={2} sm={3} className="session-controls-wrapper dummy3">
                            <div className="session-controls">
                                <div className="buttons-wrapper">
                                    <IconButton
                                        aria-label="choose-language"
                                        onClick={changeLaguage}
                                        edge="end"
                                        className="session-control-btn change-lang-btn"
                                    >
                                        <Typography variant='body1'>{ this.state.config.language === "de" ? "DE" : "EN" }</Typography>
                                    </IconButton>
                                </div>
                                <div className="buttons-wrapper twin-button">
                                    { this.token ? 
                                            <IconButton
                                                id="logout-btn"
                                                aria-label="logout"
                                                onClick={this.logout}
                                                edge="end"
                                                className={"session-control-btn" + (this.state.config.isEventLoaded === true ? " disabled-logout-btn" : "") }
                                            >
                                                { 
                                                    this.state.config.isEventLoaded === true ? 
                                                        <NoEncryptionIcon className="disabled-logout-svg" />
                                                        :
                                                        <LockIcon />
                                                }
                                            </IconButton>
                                        : "" 
                                    }
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </header>
                <div className="interactive-area-wrapper">
                    <div id="scanner-working-area" className="scanner-working-area">
                        <div className="qr-reader-container">
                            <Grid container sx={{position: 'absolute', zIndex: '990'}}>
                                <Grid item xs={6}>
                                    <div className={"camera-icon" + (this.state.config.isCameraActive ? " camera-active" : " camera-inactive")}>
                                        <IconButton
                                            aria-label="toggle camera on/off"
                                            onClick={this.toogleCamera}
                                            edge="end"
                                        >
                                        <PhotoCameraOutlinedIcon 
                                            sx={{fontSize:"calc(20px + 5vmin)"}}
                                        />
                                        </IconButton>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    { 
                                        this.state.config.supportsTorch === true ?
                                            <div className={"light-icon" + (this.state.config.isLightOn ? " light-active" : " light-inactive")}>
                                                <IconButton
                                                    aria-label="toggle torch on/off"
                                                    onClick={toogleTorch}
                                                    edge="end"
                                                >
                                                    <EmojiObjectsOutlinedIcon sx={{fontSize:"calc(20px + 5vmin)"}} />
                                                </IconButton>
                                            </div>
                                        :
                                            null
                                    }
                                </Grid>
                            </Grid>
                            {
                                (this.state.config.isCameraActive) ?
                                (<BarcodeScannerComponent
                                    torch={this.state.config.isLightOn}
                                    onUpdate={onReaderScan}
                                    onError={onReaderError}
                                    delay={this.state.config.scanDelay}
                                    stopStream={!this.state.config.isCameraActive}
                                    className="qr-reader"
                                />)
                                :
                                <CameraDisabled 
                                    lang={this.state.config.language}
                                />
                            }
                        </div>
                    </div>
                    <div id="scan-status-area" className="scan-status-area">
                        <div id="scan-status-notif" className="scan-status-notif">
                            <div id="version-section" className="version"><span>$$version$$</span></div>
                            {
                                (this.state.eventControlConfig.showStatistics === true) ? 
                                <span id="statistics-section" className={ "statistics" + (this.state.eventControlConfig.emergencyMode === true ? " emergency-mode" : "")}>{this.state.eventLoadedStats.admission_inside_count} / {this.state.eventLoadedStats.admission_outside_count} / {this.state.eventLoadedStats.admission_total_ticket_count}</span>
                                : ""
                            }
                            {
                            (this.state.config.isEventLoaded === false) ? 
                                (
                                    (<InfoStatus 
                                        msg={(this.state.config.language === "de" ? "Eventcode scannen oder eingeben" : "Scan or type an event code")} 
                                    />)
                                )
                                : 
                                (
                                    (this.state.ticket.code === "") ?
                                    (<InfoStatus 
                                        msg="" 
                                    />)
                                    :
                                    (this.state.ticket.status === "valid" ? 
                                        <SuccessStatus 
                                            ticket={this.state.ticket}
                                            eventControlConfig={this.state.eventControlConfig}
                                        /> : 
                                        <ErrorStatus 
                                            ticket={this.state.ticket}
                                            errorCodes={this.state.errorCodes}
                                            lang={this.state.config.language}
                                            eventControlConfig={this.state.eventControlConfig}
                                        />
                                    )
                                )
                            }
                        </div>
                        <Grid container className="ticket-input-ctrl">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={10}>
                            <OutlinedInput
                                id="code-input-txt"
                                fullWidth 
                                type='text'
                                size="small"
                                className="txt-box"
                                value={this.state.values.codeTxt}
                                autoComplete="off"
                                onChange={handleManualCodeInput}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => { document.getElementById("code-input-txt").focus(); }}
                                    edge="end"
                                    className="focus-textbox-icon"
                                    >
                                    <KeyboardOutlinedIcon color="darkgray" />
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                    </div>
                </div>
                
                { this.state.eventLoadedStats.showDialog ? 
                    <EventLoadModal
                        eventLoadedStats={this.state.eventLoadedStats}
                        unmountEventDialog={unmountEventDialog}
                        language={this.state.config.language}
                    />
                : ""
                }

                { this.state.eventControlConfig.showDialog ? 
                    <EventControlModal
                        eventControlConfig={this.state.eventControlConfig}
                        unmountControlDialog={unmountControlDialog}
                        updateControlSettings={updateControlSettings}
                        openEventDetailsModal={openEventDetailsModal}
                        closeEvent={closeEvent}
                        isEventLoaded = {this.state.config.isEventLoaded}
                        language={this.state.config.language}
                    />
                : ""
                }

                { this.state.eventLoadedStats.showHints ? 
                    <EventLoadHints
                        eventLoadedStats={this.state.eventLoadedStats}
                        unmountHintsDialog={unmountHintsDialog}
                        language={this.state.config.language}
                        app="scan-app"
                    />
                : ""
                }

                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={this.state.config.showSpinner}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
                        
            </div>
        );
    }
}

export default Scanner;