import React from 'react';


export default function Layout({children}) {
    return (

    <div id="page-wrapper" className="page">
        {children}
    </div>
    )
}