import React from 'react';
import { Snackbar, SnackbarContent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { CheckCircleOutlined, CloseOutlined } from '@mui/icons-material';


export default function NotificationSnackbar({ snackbarConf, openSnackbar, snackbarClose }){
    return (
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            sx={{zIndex: "9999", marginTop: "6vh", fontSize:"calc(8px + 2vmin)"}}
            open={openSnackbar}
            autoHideDuration={snackbarConf.hideTimeout}
            onClose={snackbarClose}
        >
            <SnackbarContent
                className='snackbar'
                action={
                    <IconButton onClick={snackbarClose}>
                        <CloseOutlined
                            sx={{color: 'white'}}
                        ></CloseOutlined>
                    </IconButton>
                }
                message={
                    <div>
                        { (snackbarConf.type === "error") ? 
                            <WarningAmberRoundedIcon /> 
                            : <CheckCircleOutlined />
                        }
                        <span className='snackbar-msg'>
                            { snackbarConf.msg }
                        </span>
                    </div> 
                }
                sx={snackbarConf.style}
            >
            </SnackbarContent>
        </Snackbar>
    );
};