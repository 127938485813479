import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { DialogContentText, Grid, List, ListItemText } from '@mui/material';
import { Box } from '@mui/system';
import ListItem from '@mui/material/ListItem';
import LogoutIcon from '@mui/icons-material/Logout';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 100,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));
  

class EventControlModal extends Component {
    state = {
        open: true,
    };
    render() {
        let eventControlConfig = this.props.eventControlConfig;
        let isEventLoaded = this.props.isEventLoaded;
        const descs = eventControlConfig.messagesDict;
        const lang = this.props.language;

        const handleEventClose = () => {
            this.props.closeEvent();
            handleClose();
        }
        const handleClose = () => {
            this.state.open = false;
            this.props.unmountControlDialog();
        };
        const updateSettings = () => {
            this.props.updateControlSettings(eventControlConfig);
        }
        const showEventDetailsModal = () => {
            this.props.openEventDetailsModal();
        }

        return (
            <div>
                <Dialog 
                    open={this.state.open}
                    onClose={handleClose}
                    maxWidth="sm"
                    fullWidth
                    className="event-load-dialog"
                    key="eventControlModal"
                    id="cp-modal"
                >
                    <p className="event-load-dialog-title">{lang === "de" ? "Einstellungen" : "Control settings"}</p>
                    <Box position="absolute" top={0} right={0}>
                        <IconButton onClick={handleClose}>
                            <CloseIcon className="event-load-dialog-close" />
                        </IconButton>
                    </Box>
                    <DialogContent>
                        <DialogContentText component="div" className="event-load-dialog-content">
                            <Grid container>
                                <Grid item xs={12} className="grid-valign-center">
                                    <List className="event-load-dialog-list">
                                        <ListItem>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <p className="event-control-modal-desc" component="span" variant='body1'>{descs["event.closeEvent"][lang]}: </p>
                                                <div className="event-control-modal-opt-wrapper">
                                                    { isEventLoaded ? 
                                                        <IconButton
                                                            id="close-event-btn"
                                                            aria-label="close-event"
                                                            onClick={handleEventClose}
                                                            edge="end"
                                                            className="session-control-btn"
                                                            color="secondary"
                                                        >
                                                            <LogoutIcon />
                                                        </IconButton>
                                                        : "" 
                                                    }
                                                </div>
                                            </Stack>
                                        </ListItem>
                                    <ListItem><Stack direction="row" spacing={1} alignItems="center"><p className="event-control-modal-desc" component="span" variant='body1'></p></Stack></ListItem>
                                    <ListItem>
                                        <Stack direction="row" className="event-control-modal-opt-div" spacing={1} alignItems="center">
                                            <p className="event-control-modal-desc" component="span" variant='body1'>{descs["event.showStatistics"][lang]}</p>
                                            <div className="event-control-modal-opt-wrapper">
                                                <p className="swt-off" component="span" variant='body1'>{descs["event.off"][lang]}</p>
                                                <AntSwitch id="cp-statistics-swt" className="swt-ctl" checked={eventControlConfig.showStatistics} onChange={()=> {eventControlConfig.showStatistics=!eventControlConfig.showStatistics; updateSettings();}} inputProps={{ 'aria-label': 'ant design' }} size="medium" />
                                                <p className="swt-on" component="span" variant='body1'>{descs["event.on"][lang]}</p>
                                            </div>
                                        </Stack>
                                    </ListItem>
                                    <ListItem>
                                        <Stack direction="row" className="event-control-modal-opt-div" spacing={1} alignItems="center">
                                            <p className="event-control-modal-desc" component="span" variant='body1'>{descs["event.activateEmergencyMode"][lang]}</p>
                                            <div className="event-control-modal-opt-wrapper">
                                                <p className="swt-off" component="span" variant='body1'>{descs["event.off"][lang]}</p>
                                                <AntSwitch id="cp-emergency-swt" className="swt-ctl" checked={eventControlConfig.emergencyMode} onChange={()=> {eventControlConfig.emergencyMode=!eventControlConfig.emergencyMode; updateSettings();}} inputProps={{ 'aria-label': 'ant design' }}  size="medium" />
                                                <p className="swt-on" component="span" variant='body1'>{descs["event.on"][lang]}</p>
                                            </div>
                                        </Stack>
                                    </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="event-load-dialog-actions">
                        <Button 
                            id="cp-close-btn"
                            variant='contained'
                            onClick={handleClose}
                        >
                            {lang === "de" ? "Schließen" : "Close"}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default EventControlModal;