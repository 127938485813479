import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContentText } from '@mui/material';
import { Box } from '@mui/system';
import hintsDE from './description-set-DE.PNG';
import hintsEN from './description-set-EN.PNG';

class EventLoadHints extends Component {
    state = {
        open: true,
    };
    render() {
        const handleClose = () => {
            this.state.open = false;
            this.props.unmountHintsDialog();
        };
        const lang = this.props.language;

        return (
            <div>
                <Dialog 
                    open={this.state.open}
                    onClose={handleClose}
                    maxWidth="sm"
                    fullWidth
                    className="event-load-dialog"
                >
                    <DialogTitle className="event-load-dialog-title" component="span" variant="h5">{lang === "de" ? "Hinweise" : "Hints"}</DialogTitle>
                    <Box position="absolute" top={0} right={0}>
                        <IconButton id="close-hints-btn" onClick={handleClose}>
                            <CloseIcon className="event-load-dialog-close" />
                        </IconButton>
                    </Box>
                    <DialogContent>
                        <DialogContentText component="div" className="event-load-dialog-content event-load-dialog-content-hints">
                            <img src={ lang === "de" ? hintsDE : hintsEN } />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="event-load-dialog-actions">
                        <Button 
                            variant='contained'
                            onClick={handleClose}
                        >
                            {lang === "de" ? "Schließen" : "Close"}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default EventLoadHints;