var config = {
    "DEV": {
        //Sync time in ms
        syncUploadTime: 150*1000,
        syncDownloadTime: 150*1000,
        syncEventDetailsTime: 150*1000
    },
    "PROD": {
        syncUploadTime: 5*1000,
        syncDownloadTime: 7*1000,
        syncEventDetailsTime: 9*1000
    }
}

export default config;