import { Grid } from '@mui/material';
import React, { Component } from 'react';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';


class SuccessStatus extends Component {
    state = {
        ticket: this.props.ticket,
        emergencyMode: this.props.eventControlConfig.emergencyMode === true ? true : false
    }
    
    render() {
        return (
            <div className="info-status-wrapper">
                <Grid container id="validation-success" className="status success">
                    <Grid item xs={12} className="ticket-owner">
                        { this.state.emergencyMode ? "" : this.state.ticket.firstName+" "+this.state.ticket.lastName }
                    </Grid>
                    <Grid item xs={1} md={1}></Grid>
                    <Grid item xs={1} md={1} textAlign="right">
                        <CheckRoundedIcon 
                            className="check-icon"
                        />
                    </Grid>
                    <Grid item xs={9} md={9}>
                        <Grid container>
                            <Grid item xs={12} md={12}>

                                <Grid item xs={12}>
                                   <span className="ticket-code"> { this.state.ticket.code }</span>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Grid item xs={12}>
                                    { this.state.emergencyMode ? "" : this.state.ticket.category }
                                </Grid>
                                <Grid item xs={12}>
                                    { this.state.emergencyMode ? "" : this.state.ticket.seating }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1} md={1}></Grid>
                </Grid>
            </div>
        );
    }
}

export default SuccessStatus;