import { syncHelper } from "../Helpers";

export const ControlConfig = (codeTXT, that) => {
            let oPSegments = (codeTXT.split("##")).join("").split("#");
            let eventLoadedStats = that.state.eventLoadedStats;
            let eventControlConfig = that.state.eventControlConfig;
            let config = that.state.config;

            if(oPSegments.length === 2 && !isNaN(oPSegments[0]) && !isNaN(oPSegments[1])){
                let configCode = that.state.configCodes.find( (val) => { return val.op === oPSegments[0] });
                if(configCode){
                    switch(oPSegments[1]){
                        case configCode.select[0]: 
                            syncHelper.syncEventDetailsInfo(that);
                            config.isCameraActive = false;
                            eventLoadedStats.showDialog = true;
                            that.setState({eventLoadedStats: eventLoadedStats, config: config});
                        break;

                        case configCode.select[1]:
                            eventControlConfig.showDialog = true;
                            eventControlConfig.scannedStr = codeTXT;
                            config.isCameraActive = false;
                            that.setState({eventControlConfig: eventControlConfig, config: config});
                        break;

                        case configCode.select[2]: 
                            console.log("Delete Feature... Not implemented");
                        break;

                        default:
                            console.log("Invalid config code provided");
                        
                    }
                }
            }
            return;
}