import { Grid } from '@mui/material';
import React, { Component } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';


class ErrorStatus extends Component {
    state = {
        ticket: this.props.ticket,
        errorCodes: this.props.errorCodes,
        lang: (this.props.lang === "de" ? 0 : 1),
        emergencyMode: this.props.eventControlConfig.emergencyMode === true ? true : false
    }
    
    render() {
        return (
            <div className="info-status-wrapper">
                <div id="validation-error" className="status error">
                                <p className="ticket-code">
                                    <CancelIcon className="check-icon" />
                                    Ticket {this.state.ticket.code}
                                </p>
                                <p className="ticket-error-codes">
                                {
                                    this.state.emergencyMode ? this.state.errorCodes[5][this.state.lang] : 
                                    this.state.ticket.errorCode.map((error) => {
                                     return <span key={this.state.errorCodes[error][1]}>{this.state.errorCodes[error][this.state.lang]+", "}</span>
                                    })
                                }
                                </p>
                                <p className="ticket-category-info">
                                    { this.state.emergencyMode ? "" : this.state.ticket.category }
                                    { this.state.emergencyMode ? "" : this.state.ticket.seating }
                                </p>
                </div>
            </div>
        );
    }
}

export default ErrorStatus;