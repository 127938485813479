//Helpers
import * as idb from 'idb';
import config from './config';
import * as CryptoLib from './Libraries/CryptoLib';
import * as AESCrypto from './Libraries/crypto-aes-gcm';

//######################################################################################
//YOU MUST SET THE env VARIABLE EITHER TO "DEV" OR "PROD" TO LOAD THE CORRECT SETTINGS 
const env = "PROD";
const settings = env === "DEV" ? config.DEV : config.PROD;
//######################################################################################
//######################################################################################

//HTTPHelper provides an abstraction for HTTP requests to the 'doors-API' Service
export const HTTPHelper = (eventCode, eventId, userContext, setUserContext, setToken) => {
    if(!eventCode){
        console.log("Bad eventCode was provided");
        //needs an event code to use API
        return null;
    }
    if(!userContext){
        console.log("User context was not provided");
        return null;
    }
    let jwt = "Bearer " + userContext.jwt;
    return {
        config: {
            BASE_URL: "https://admission.tickets.de/scanner/v2",
            BASE_AUTH_URL: "https://admission.tickets.de/v2/authenticate",
            EVENT_CODE: eventCode,
            EVENT_ID: eventId
        },
        headers: {
            Authorization: jwt,
            Accept: "application/json"
        },
        getEvent: function(){
            let url = this.config.BASE_URL+"/events/"+this.config.EVENT_ID+"/show";
            return this.customFetch(url,{
                method: "GET",
                headers: this.headers
            }).catch((err) => {
                console.log("ERROR Getting Event Details from server, Request Error:",err);
                return null;
            });
        },
        getScanQueueItems: function(start, count){
            if(isNaN(start) || start < 1){
                start = 1;
             }
             if(isNaN(count) || count < 1){
                count = 100;
             }

            let url = this.config.BASE_URL+"/events/"+this.config.EVENT_ID+"/scan_queue_items?last_id="+start+"&limit="+count;
            return this.customFetch(url,{
                method: "GET",
                headers: this.headers
            }).catch((err) => {
                console.log("ERROR Getting Scan Queue Items from server, Request Error:",err);
            });
        },
        getAllScanQueueItems: function(){
            let url = this.config.BASE_URL+"/events/"+this.config.EVENT_ID+"/scan_queue_items";
            return this.customFetch(url,{
                method: "GET",
                headers: this.headers
            }).catch((err) => {
                console.log("ERROR Getting ALL Scan Queue Items from server, Request Error:",err);
            });
        },
        sendScannerItems: function(body){
            let url = this.config.BASE_URL+"/events/"+this.config.EVENT_ID+"/scan_queue_items";
            this.headers["Content-Type"] = "text/plain; charset=utf-8";

            let configObj = {
                    method: "POST",
                    headers: this.headers,
                    body: body
            };
            return this.customFetch(url, configObj).catch((err) => {
                        console.log("ERROR Sending Scanner Items from server, Request Error:",err);
                    });
        },
        getAccessToken: function(){
            const url = this.config.BASE_AUTH_URL+"/refreshtoken";
            return fetch(url,{
                method: "POST",
                headers: { "Content-Type": "application/json", "Accept": "application/json" },
                body: JSON.stringify({
                    "AccessToken": userContext.jwt,
                    "RefreshToken": userContext.refreshJwt
                    // "RefreshToken": "waaaaaaa"
                })
            }).catch((err) => {
                console.log("ERROR Getting new JSON Web Token from Auth Refresh URL, Request Error:",err);
            });
        },
        customFetch: function(url, configObj){
            let isAccessTokenValid = authHelper.checkAccessTokenExpire(userContext);
            let expiringOn = new Date(userContext.expiration*1000);
            if(!isAccessTokenValid){
                return authHelper.refreshAccessToken(userContext, setUserContext, setToken).then( (result) => {
                    if(result){
                        configObj.headers.Authorization = "Bearer " + userContext.jwt;
                        return fetch(url, configObj);
                    }
                    else
                    {
                        throw new Error("customFetch Error: AccessToken is expired and failed to refresh token");
                    }
                });
            }
            return fetch(url, configObj);
        }
    }
}

//scanItemHelper helps to run transformation and formatting of scan-queue-items downloadeded from the server
export const scanItemHelper = {
    parseScanitems: function(rawTxt){
        let plain = `${rawTxt}`;
	    let s = plain[plain.length-1] === "\n" ? plain.substr(0, plain.length-2) : plain;
	    s = "[" + s.trim().replaceAll("\n", ",") + "]";
	    let scanQueueItems = JSON.parse(s);
        if( Array.isArray(scanQueueItems) && scanQueueItems.length > 0){
            return scanQueueItems;
        }
        else{
            return [];
        }
    }
}

//idbHelper provides an abstraction for handling IndexedDB operations
export const idbHelper = {
    //This function must be called before using any of the other functions in the helper.
    //Opens an indexedDB database and returns a promise for an indexedDB Interface, which
    //can be later passed to the other functions of this helper for interacting with the 
    //"tickets-pwa" database. If the "tickets-pwa" database found in the browser is older 
    //than the version requested, then a series of upgrade transformations will be run 
    //in oder to bring it to the latest version.
    openDb: () => {
        return idb.openDB("tickets-pwa", 2, {
            upgrade(db, oldVersion){
                console.log("Upgrading from DB oldVersion: "+oldVersion+", DB latest version: "+db.version);
                switch(oldVersion){
                    case 0:
                        let eventStore = db.createObjectStore("event",{keyPath: "id"});
                        let scanQueueItemsStore = db.createObjectStore("scanqueueitems",{keyPath: "id"})
                                                    .createIndex("ticket-code","data.code");
                        let settingsStore = db.createObjectStore("settings",{keyPath: "id"});
                        let scannerItemsStore = db.createObjectStore("scanneritems",{autoIncrement: true});
                    case 1:
                        let authStore = db.createObjectStore("auth",{keyPath: "id"});
                }
            }
        });
    },
    //Retrieves a list of events saved on the 'event' DB Storage
    getEvents: (db) => {
        return db.then((db)=>{
            let tx = db.transaction("event", "readwrite");
            let eventStore = tx.objectStore("event");
            return eventStore.getAll().then((events) => {
                return events;
            });
        }).catch((err) => {
            console.log("Error on getEvent: ",err);
            return null;    
        });

    },
    //Inserts or updates an event on the 'event' DB Storage.
    saveEvent: (db, event) => {
        return db.then((db) => {
            let tx = db.transaction("event", "readwrite");
            let eventStore = tx.objectStore("event");
            eventStore.put(event).then(() => {
                return tx.complete;
            });
        }).catch((err) => {
            console.log("Error on saveEvent: ",err);
            return null;    
        });
    },
    getSettings: (db) => {
        return db.then((db) => {
            let tx = db.transaction("settings");
            let settingsStore = tx.objectStore("settings");
            return settingsStore.get(1).then((settings) => {
                return settings;
            });
        }).catch((err) => {
            console.log("Error on saveSettings: ",err);
            return null;    
        });
    },
    //Updates the only record that should exist in the 'settings' Storage
    //In order to guarantee an update instead of inserting a new record,
    //the 'id' property of the 'settings' object must be set to 1
    saveSettings: (db, settings) => {
        return db.then((db) => {
            let tx = db.transaction("settings", "readwrite");
            let settingsStore = tx.objectStore("settings");
            settingsStore.put(settings).then((res) => {
                return tx.complete;
            });
        }).catch((err) => {
            console.log("Error on saveSettings: ",err);
            return null;    
        });
    },
    //Retrieves all the Keys and Values as two separate from 'scanneritems' Storage
    getAllScannerItemKeys: (db) => {
        return db.then((db) => {
            let tx = db.transaction("scanneritems");
            let scanneritemsStore = tx.objectStore("scanneritems");
            let scannerItems = [];
            let promises = [];

            return scanneritemsStore.getAllKeys().then((scannerItemKeys) => {
                return scannerItemKeys;
            }).then((keys) => {
                keys.forEach((key) => {
                    promises.push(
                        scanneritemsStore.get(key).then((item) => {
                            scannerItems.push(item);
                        })
                    );
                });
                return Promise.all(promises).then(() => {
                    return [keys, scannerItems];     
                })
            })
        }).catch((err) => {
            console.log("Error on getAllScannerItemKeys: ",err);
            return null;    
        });
    },
    //Retrieves all the records from 'scanneritems' Storage
    getAllScannerItems: (db) => {
        return db.then((db) => {
            let tx = db.transaction("scanneritems");
            let scanneritemsStore = tx.objectStore("scanneritems");
            
            return scanneritemsStore.getAll().then((scannerItems) => {
                return scannerItems;
            });
        }).catch((err) => {
            console.log("Error on getAllScannerItems: ",err);
            return null;    
        });
    },
    deleteScannerItems(db, keys){
        return db.then((db) => {
            let tx = db.transaction("scanneritems","readwrite");
            let scanneritemsStore = tx.objectStore("scanneritems");
            
            keys.forEach((key) =>{
                scanneritemsStore.delete(key);
            });
            return tx.complete;
        }).catch((err) => {
            console.log("Error on deleteScannerItems: ",err);
            return null;    
        });
    },
    //Generic function for deleting all the records from a specific DB Store identified by 'storeName'
    clearStore: (db, storeName) => {
        return db.then((db) => {
            let tx = db.transaction(storeName,"readwrite");
            let store = tx.objectStore(storeName);
            
            return store.clear().then((res) => {
                return tx.complete;
            });
        }).catch((err) => {
            console.log("Error on clearStore: ",err);
            return null;
        });
    },
    //Receives an array (items) of 'scanqueueitems' objects and inserts OR updates each of the items on the 'scanqueueitems' Store
    //Since the 'scanqueueitems' Store was created using the 'id' property as Key. This means the 'put' method will check
    //if the id of the item on stage matches the id of any of the objects already saved on the Store, should that be the case
    //then that object gets updated/replaced with the item that you are trying to insert. If the id does not match, then
    //the item on stage will be inserted into the Storage as a new record/object. 
    saveScanQueueItems: (db, items) => {
        return db.then((db) => {
            let tx = db.transaction("scanqueueitems","readwrite");
            let scanqueueitemsStore = tx.objectStore("scanqueueitems");
            
            items.forEach(item => {
                scanqueueitemsStore.put(item);
            });
            return tx.complete;
        }).catch((err) => {
            console.log("Error on save scanqueueitems: ",err);
            return null;
        });
    },
    //Uses the 'ticket-code' index to retrieve a list of Scan-Queue-Items ordered by 'code' from the 'scanqueueitems' Store
    getTicketScanQueueItems: (db, ticketCode) => {

        return db.then((db) => {
            // let tx = db.transaction("scanqueueitems");
            // let scanqueueitems = tx.objectStore("scanqueueitems");
            return db.getAllFromIndex("scanqueueitems", "ticket-code", ticketCode).then((ticketItems) => {
                return ticketItems;
            });
        }).catch((err) => {
            console.log("Error on getTicketScanQueueItems: ",err);
            return null;    
        });
    }, 
    //Adds testing items to the 'scanqueueitems' store 
    addScanQueueItems: (db) => {
        //WARNING!!
        //  This is only provided for testing adding DUMMY entries to Scan-Queue-Items store
        //WARNING!!
       // Adding dummy data:
       console.warn("Inserting Dummy Scan-Queue-Items to indexedDB");
       let addArr = [];
        let g = {
            "id": 5936744,
            "event_id": 11547,
            "data": {
                "event_type": "ticket_addition",
                "code": "0005194384893190",
                "ticket_category_id": "16828-32414",
                "ticket_category": "Test Stehplatz Innenraum Test Import CSV",
                "ticket_type": "voucher",
                "valid_from": null, //2022-02-12T17:07:49.818Z
                "valid_through": null,
                "admission_count": 3,
                "state": "invalidated",
                "order_last_name": "Z",
                "order_first_name": "E",
                "order_email": "ptk@tickets.de",
                "owner_last_name": "a",
                "owner_first_name": "a",
                "owner_email": "carima.elbasyouny@tickets.de",
                "seating": "",
                "notes": "23-06-17 11:45:02 sold: "
            }
        }
        db.then(function(db){
            for(var i=1;i<4;i++){
                g.id = g.id+1;
                g.data.event_type = (g.data.admission_count > 0) ? "ticket_change" : "scan_failed";
                g.data.admission_count = (g.data.admission_count > 1) ? (g.data.admission_count -1) : 0;
            
                let tx = db.transaction("scanqueueitems","readwrite")
                let scanqueueitemsStore = tx.objectStore("scanqueueitems");
                scanqueueitemsStore.put(g);
            }
        });
    },

    saveScannerItem: (db, scannerItem) => {
        return db.then((db) => {
            let tx = db.transaction("scanneritems","readwrite");
            let scanneritemsStore = tx.objectStore("scanneritems");
            return scanneritemsStore.add(scannerItem);
            // return tx.complete;
        }).catch((err) => {
            console.log("Error on save scanneritem: ",err);
            return null;
        });
    },
    getLastSQIKey: (db) => {
        return db.then((db) => {
            let tx = db.transaction("scanqueueitems");
            let scanQueueItemsStore = tx.objectStore("scanqueueitems");
            return scanQueueItemsStore.openKeyCursor(null, "prev");
        }).then((cursor) => {
            if(!cursor){
                return false;
            }
            return cursor.key;
        });
    },
    getAuthenticationObj: (db) => {
        return db.then( (db) => {
            let tx = db.transaction("auth");
            let authObj = tx.objectStore("auth");
            
            return authObj.getAll().then( (records) => {
                if( records.length > 0 ){
                    return records[0];
                }
                else{
                    return {};
                }
            });
        });
    },
    saveAuthenticationObj: (db, authObj) => {
        return db.then((db) => {
            let tx = db.transaction("auth", "readwrite");
            let authStore = tx.objectStore("auth");
            authStore.put(authObj).then(() => {
                return tx.complete;
            });
        }).catch((err) => {
            console.log("Error on saveAuthObj: ",err);
            return null;    
        });
    }
}

//validationHelper provides functions for common ticket validation checks
export const validationHelper = {
    //Orders an array of Scan-Queue-Items by 'id' DESCENDING. This assumes that the id property of the 
    //Scan-Queue-Items received from the Server is unique and it auto-increments for each new item inserted.
    getNewestItem: (arr) => {
        arr.sort((a,b) => { return b.id - a.id });
        return arr[0];
    },
    //Takes a list of Scan-Queue-Items determines if state of the associated ticket is valid
    //Returns valid='true'  or invalid='false'
    checkItemState: (ticketScanQueueItems) => {
        if(!ticketScanQueueItems || ticketScanQueueItems.length === 0) return false;
        //(Queue-Item.state == "sold" OR Queue-Item.state == "revalidated"): then Ticket is VALID
        //To verify the above business rule, the procedure will
        //  1) Get the most recent Scan-Queue-Item
        //
        let lastTicketScanQueueItem = validationHelper.getNewestItem(ticketScanQueueItems);
        //  2) Check the item state, if equals "sold" the ticket state is valid, if equals "revalidated" the ticket is valid.
        //     Any other value represents an invalid ticket state
        return (lastTicketScanQueueItem.data.state === "sold" || lastTicketScanQueueItem.data.state === "revalidated") ? true : false;
    },
    //It receives an array of Scan-Queue-Items and determines if the most recent item has "admission_count" > 0
    checkItemAccess: (ticketScanQueueItems) => {
        if(!ticketScanQueueItems || ticketScanQueueItems.length === 0) return false;
        
        let lastTicketScanQueueItem = validationHelper.getNewestItem(ticketScanQueueItems);
        return (lastTicketScanQueueItem.data.admission_count > 0) ? true : false;
    },
    checkScannerItemsAccess: (db, ticketCode) => {
        return idbHelper.getAllScannerItems(db).then((scannerItems) => {
            if(scannerItems){
                let ticketScannerItems = scannerItems.filter((item) => {return (item.data.code === ticketCode)});
                if(ticketScannerItems.length === 0){
                    return true;
                }
                else{
                    ticketScannerItems.sort((a,b) => { return Date.parse(b.data.changed_at) - Date.parse(a.data.changed_at) });
                    let lastScannerItem = ticketScannerItems[0];
                    return (lastScannerItem.data.admission_count > 0) ? true : false;
                }
            }
            else{
                return true;
            }
        });
    },
    //It receives an array of Scan-Queue-Items and evaluates the 'valid_from'/'valid_through' properties
    //Date format expected: 'YYYY-MM-ddTHH:mm:ss.sssZ' ISO8601
    checkTime: (ticketScanQueueItems) => {
        if(!ticketScanQueueItems || ticketScanQueueItems.length === 0) return false;
        
        let lastTicketScanQueueItem = validationHelper.getNewestItem(ticketScanQueueItems);
        let currentDate = new Date();
        let valid_from = lastTicketScanQueueItem.data.valid_from ? (new Date(lastTicketScanQueueItem.data.valid_from)) : null;
        let valid_through = lastTicketScanQueueItem.data.valid_through ? (new Date(lastTicketScanQueueItem.data.valid_through)) : null;
        
        //If the valid_from and valid_through properties are not set, then the ticket does not need 
        //to be verified against time. The checkTime function returns 'true'
        if(valid_from && valid_through){
            //Validating both valid_from and valid_through
            return (currentDate > valid_from && currentDate < valid_through) ? true : false;
        }
        else if(valid_from){
            //Validating only valid_from
            return (currentDate > valid_from) ? true : false;
        }
        else if(valid_through){
            //Validating only valid_through
            return (currentDate < valid_through) ? true : false;
        }
        else{
            //When valid_from or valid_through are set, then do not validate and return true
            return true;
        }
    },
    //Receives an array of Scan-Queue-Items and returns the associated ticket details of the most recent item
    getTicketDetails: (ticketScanQueueItems) => {
        let newest = validationHelper.getNewestItem(ticketScanQueueItems);
        return {
            code:       newest.data.code,
            firstName:  newest.data.owner_first_name,
            lastName:   newest.data.owner_last_name,
            validFrom:  newest.data.valid_from,
            validThrough: newest.data.valid_through,
            category:   newest.data.ticket_category,
            seating:    newest.data.seating,
            admissionCount: newest.data.admission_count,
        }
    }
}

//syncHelper provides functions for running the synchronization process between the remote server and the app
export const syncHelper = {
    syncScannerItems: (context) => {
        let db = idbHelper.openDb();
        let userContext = context.userContext;
        let setUserContext = context.setUserContext;
        let setToken = context.setToken;

        idbHelper.getAllScannerItemKeys(db).then((result) => {
            if(!result || !Array.isArray(result) || !result.length > 0 || !result[0].length > 0 || !result[1].length > 0){
                return false;
            }
            let keys = result[0];
            let items = result[1];
            let eventId = items[0].id;
            let syncObj = "id="+eventId+"&data="+JSON.stringify(items);
            
            idbHelper.getSettings(db).then((settings) => {
                let eventCode = settings.eventCode;
                let httpHelper = HTTPHelper(eventCode, eventId, userContext, setUserContext, setToken);

                httpHelper.sendScannerItems(syncObj).then((res) => {
                    if(res.status === 200){
                        //  1) Delete sent Items from IndexedDB
                        idbHelper.deleteScannerItems(db, keys).then(() => {
                            //TODO:
                            //Once Scanner Items are removed from indexedDB 'ScannerItems' Object Store
                            //then save the synched operation to internal logs
                            let uploadedCount = settings.uploaded + keys.length;
                            syncHelper.syncTicketCount(db, context, [uploadedCount, null]);
                        }).catch((error) => {
                            console.log("Failed to delete Scanner Items from Store");
                        });
                        //  2) Update the settings table with total of Uploaded/Synched tickets count
                        //  3) Update flags for App header
                    }
                    else{
                        console.log("Server returned an error while sending Scanner Items to server");
                        throw new Error("Server returned an error while sending Scanner Items to server");
                    }
                }).catch((error) => {
                    console.log("ERROR Getting Scanner Items from server during sync");
                });
            });
        });
    },
    syncScanQueueItems: (context) => {
        let db = idbHelper.openDb();
        let userContext = context.userContext;
        let setUserContext = context.setUserContext;
        let setToken = context.setToken;
        let limit = 500; //Maximum number of records to return

        idbHelper.getSettings(db).then((settings) => {
            let eventCode = settings.eventCode;
            let eventId = settings.eventId;
            let httpHelper = HTTPHelper(eventCode, eventId, userContext, setUserContext, setToken);

            idbHelper.getLastSQIKey(db).then((key) => {
                if(!key){
                    console.log("syncScanQueueItems, error getting the id of the las Scan-Queue-Item, aborted sync");
                    return false
                }
                httpHelper.getScanQueueItems(key, limit).then((result) => {
                    if(result.status === 200){
                        return result.text().then((data) => {
                            let scanQueueItems = scanItemHelper.parseScanitems(data);
                            idbHelper.saveScanQueueItems(db, scanQueueItems).then(() => { 
                            });
                            return scanQueueItems;
                        });
                    }
                    else{
                        console.log("An error occurred during the synchronization of Scan-Queue-Items");
                    }
                }).catch((error) => {
                    console.log("ERROR Failed to get Scan Queue Items from server during sync:", error);
                });
            });
        });
    },
    syncTicketCount: (db, context, [uploadedCount, scannedCount]) => {
        let config = context.state.config;
        idbHelper.getSettings(db).then((settings) => {
            if(uploadedCount !== null && !isNaN(uploadedCount) ){
                settings.uploaded = uploadedCount;
                config.uploaded = uploadedCount;
            }
            if(scannedCount !== null && !isNaN(scannedCount) ){
                settings.scanned = scannedCount;
                config.scanned = scannedCount;
            }
            idbHelper.getAllScannerItems(db).then((scannerItems) => {
                if(scannerItems && Array.isArray(scannerItems) && scannerItems.length > 0){
                    settings.scannedInQueue = scannerItems.length;
                    config.scannedInQueue = scannerItems.length;
                }
                else{
                    settings.scannedInQueue = 0;
                    config.scannedInQueue = 0;
                }
                idbHelper.saveSettings(db, settings).then(() => {
                    context.setState({config: config});
                }).catch((err) => {
                    console.log("An error occurred while saving settings: ", err);
                });
            }).catch((err) => {
                console.log("An error occurred while getting Scanner Items to count: ", err);
            });
        });
    },
    syncEventDetailsInfo: (context) => {
        let db = idbHelper.openDb();
        let userContext = context.userContext;
        let setUserContext = context.setUserContext;
        let setToken = context.setToken;

        const restoreFromIdb = (scannedStr, eventCode, eventId) => {
            idbHelper.getEvents(db).then(function(data) {
                data = data[0];
                context.populateEventLoadDetails(scannedStr, eventCode, eventId, data);
            });
        }

        idbHelper.getSettings(db).then((settings) => {
            let eventCode = settings.eventCode;
            let eventId = settings.eventId;
            let httpHelper = HTTPHelper(eventCode, eventId, userContext, setUserContext, setToken);
            
            httpHelper.getEvent().then((res) => {
                if(res.status === 200){
                    return res.json().then(function(data) {
                        data.last_update = new Date().toISOString();
                        context.populateEventLoadDetails("Cron-Sync-Event", eventCode, eventId, data);
                        idbHelper.saveEvent(db, data);
                    })
                }
                else{
                    //restore from IndexedDB
                    console.log("ERROR Getting Event Details from server, Server Status:",res.status);
                    restoreFromIdb("Cron-Sync-Event", eventCode, eventId);
                }
            }).catch((err) => {
                //restore from IndexedDB
                console.log("ERROR Getting Event Details from server, Request Error:",err);
                restoreFromIdb("Cron-Sync-Event", eventCode, eventId);
                return null;
            });
        })
    },

    registerSyncTasks: (context) => {
        let syncHandlers = context.state.syncHandlers;
        let eventControlConfig = context.state.eventControlConfig;

        syncHelper.unregisterSyncTasks(context);
        if(syncHandlers.syncUpload === null || syncHandlers.syncUpload < 1){
            syncHandlers.syncUpload =  setInterval(function(context){ syncHelper.syncScannerItems(context) }, settings.syncUploadTime, context);
        }
        if(syncHandlers.syncDownload === null || syncHandlers.syncDownload < 1){
            syncHandlers.syncDownload = setInterval(function(context){ syncHelper.syncScanQueueItems(context) }, settings.syncDownloadTime, context);
        }
        if(eventControlConfig.showStatistics === true && (syncHandlers.syncEventDetails === null || syncHandlers.syncEventDetails < 1) ){
            syncHandlers.syncEventDetails = setInterval(function(context){ syncHelper.syncEventDetailsInfo(context) }, settings.syncEventDetailsTime, context);
            syncHelper.syncEventDetailsInfo(context);
        }
        context.setState({syncHandlers: syncHandlers});
    },    
    unregisterEventDetailsSync: (context) => {
        let syncHandlers = context.state.syncHandlers;

        if(syncHandlers.syncEventDetails !== null && syncHandlers.syncEventDetails > 0){
            clearInterval(syncHandlers.syncEventDetails);
            syncHandlers.syncEventDetails = null;
            context.setState({syncHandlers: syncHandlers});
        }
    },
    unregisterSyncTasks: (context) => {
        let syncHandlers = context.state.syncHandlers;
        if(syncHandlers.syncUpload !== null && syncHandlers.syncUpload > 0){
            clearInterval(syncHandlers.syncUpload);
            syncHandlers.syncUpload = null;
        }
        if(syncHandlers.syncDownload !== null && syncHandlers.syncDownload > 0){
            clearInterval(syncHandlers.syncDownload);
            syncHandlers.syncDownload = null;
        }
        if(syncHandlers.syncEventDetails !== null && syncHandlers.syncEventDetails > 0){
            syncHelper.unregisterEventDetailsSync(context);      
        }
        context.setState({syncHandlers: syncHandlers});
    }
}


//DEV Helper
export const devHelper = {
    getAllTicketCodes: (db) => {
        return db.then((db) => {
            return db.getAllFromIndex("scanqueueitems", "ticket-code").then((ticketItems) => {
                // console.log("getAllTicketCodes: ", ticketItems);
                return ticketItems;
            });
        }).catch((err) => {
            console.log("Error on getAllTicketCodes: ",err);
            return null;    
        });
    }
}

//Authentication Helper
//Provides functions for common authentication tasks based on JWT
export const authHelper = {
    //Checks if an authentication object exists in indexedDB vault
    jwtExists: () => {
        let db = idbHelper.openDb();
        return idbHelper.getAuthenticationObj(db).then( (authObj) => {
            if(Object.keys(authObj).length > 0){
                return true;
            }
            else{
                return false;
            }
        });
    },
    getUserRoles: (roles) => {
        //Currently available roles in API are: "scanner" and "troubleshooter"
        //When only one role is assigned to the user, the "roles" field is a simple string, whereas
        //If the user has many roles assigned, the "roles" field will be an Array of Strings
        let userRoles = [];
        let typeOfVar = (typeof(roles)).trim().toLowerCase();
     
        if( typeOfVar === "string" && roles.length > 0){
            //Only one role is assigned
            userRoles.push(roles);
            return userRoles;
        }
        else if( Array.isArray(roles) && roles.length > 0 ){
            //more than 1 role was assigned
            return roles;
        }
        else{
            //This is an error, return an empty array
            return userRoles;
        }
    },
    isRoleAllowed: (roles) => {
        let userRoles = [];
        let allowedRole = "scanner";
        userRoles = authHelper.getUserRoles(roles); console.log("isRoleAllowed assigned roles: ",userRoles);
        return userRoles.indexOf(allowedRole) > -1 ? true : false;
    },
    restoreSession: () => {
        let db = idbHelper.openDb();
        let isSessionValid = false;

        return idbHelper.getAuthenticationObj(db).then( async (data) => {
            if(Object.keys(data).length > 0){
                //Unencrypt and validate user info
                const username = data.username;
                const encKey = await CryptoLib.hashStr(username.toLowerCase().trim());

                return AESCrypto.aesGcmDecrypt(data.authObj, encKey).then( (unencryptedAuthObj) => {
                    data.token = JSON.parse(unencryptedAuthObj);

                    if(Object.keys(data.token).length > 0 
                        && username.trim().toLowerCase() === data.token.username.trim().toLowerCase() ){
                        isSessionValid = true;
                        console.log("Restored Session is Valid");
                    }
                    else{
                        isSessionValid = false;
                        console.log("Restored Session is NOT Valid");
                    }
                    return [isSessionValid, data];
                }).catch((err) => {
                    console.log("ERROR decrypting JWT, AESCrypto.aesGcmDecrypt Error:",err);
                });
            }
            else{
                return [isSessionValid, {}];
            }
        });
    },
    encryptAuthObj: (authObj, encKey) => {
        return AESCrypto.aesGcmEncrypt(JSON.stringify(authObj), encKey);
    },
    loginUser(username, password){
        const AUTH_URL = "https://admission.tickets.de/v2/authenticate/login";
        return fetch(AUTH_URL,{
            method: "POST",
            headers: { "Content-Type": "application/json", "Accept": "application/json" },
            body: JSON.stringify({
                "username": username,
                "password": password
            })
        }).catch((err) => {
            console.log("ERROR Getting JSON Web Token from Login URL, Request Error:",err);
        });
    },
    logoutUser(userContext, setUserContext, setToken){
        let db = idbHelper.openDb();
        
        // 1) Delete auth store item in indexedDB
            return idbHelper.clearStore(db, "auth").then( () => {
                // 2) Clear userContext variable
                this.clearUserContext(userContext, setUserContext);
                // 3) Go back to login screen
                setToken(false);
                console.log("User logout successful");
                return true;
            }).catch( (err) => {
                console.log("Error on logoutUser. Error: ",err);
                return false;
            });
    },
    getTokenInfo: (token) => {
        return CryptoLib.parseJwt(token);
    },
    saveToken: async (userContext) => {
        let dbPromise = idbHelper.openDb();
        const encKey = await CryptoLib.hashStr(userContext.username.toLowerCase().trim());

        return authHelper.encryptAuthObj(userContext, encKey).then( (encryptedObj) => {
                    idbHelper.saveAuthenticationObj(dbPromise, { id: 1, username: userContext.username, authObj: encryptedObj });
        });
    },
    checkAccessTokenExpire: (userContext) => {
        //Compensation time used to trigger the Access Token refresh if it will be expiring in the next 5 seconds
        let compensationTime = 10*1000; //By default time is expressed in milliseconds, counting after the default date according to JWT standard
        let tokenExpiration = userContext.expiration*1000;
        let expirationTime = tokenExpiration - compensationTime;
        let currentTime = new Date().getTime();

        let expirationTimeStr = new Date(expirationTime);
        let currentTimeStr = new Date();
        // console.log("checkAccessTokenExpire expirationTime: "+expirationTimeStr+", currentTime: "+currentTimeStr);
        if(expirationTime <= currentTime){
            //If the token is already expired or will be expiring in the next 10 seconds then consider the Access Token as not valid anymore and trigger the refresh
            return false;
        }
        else{
            return true;
        }
    },
    refreshAccessToken: (userContext, setUserContext, setToken) => {
        let httpHelper = HTTPHelper("0", "0", userContext, setUserContext, setToken);
        return httpHelper.getAccessToken().then( (result) => {
                if(result.status === 200){
                    return result.json().then( (data) => {
                        let info = authHelper.getTokenInfo(data.AccessToken);
                        userContext.jwt = data.AccessToken;
                        userContext.refreshJwt = data.RefreshToken;
                        userContext.expiration = info.exp;

                        //Save token to IndexedDB
                        return authHelper.saveToken(userContext).then( () => {
                            setUserContext(userContext);
                            return true;
                        }).catch( (err) => { 
                            console.log("Error during refreshAccessToken save, Error: ", err);
                            return false;
                        });
                    });
                }
                else if(result.status === 401){
                    //Once the AccessToken can not be renewed with the existing RefreshToken, it means the session has expired
                    //Hence the user flow will be redirected to logout function
                    authHelper.logoutUser(userContext, setUserContext, setToken);
                    return false;
                }
                else{
                    return false;
                }
        }).catch( (err) => { 
            console.log("refreshAccessToken Error: ",err);
            return false;
        });
    },
    clearUserContext: (userContext, setUserContext) => {
        userContext.username =   "";
        userContext.jwt =        "";
        userContext.refreshJwt = "";
        userContext.expiration = 0;
        userContext.role =       ""; //for several roles it would return an array ['scanner', 'troubleshoot?']
        setUserContext(userContext);
    }
};